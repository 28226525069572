import React from 'react'
import Layout from '../components/layout/layout'
import SectionLeft from '../components/sections/section-number-left'
import SectionRight from '../components/sections/section-number-right'
import Footer from '../components/footer'
import TextBold from '../components/sections/components/text-bold'
import Text from '../components/sections/components/text'
import RightPlaceForAds from '../images/right_place_for_ads.svg'
import RealInterest from '../images/real_interest.svg'
import LocalMarketing from '../images/local_marketing.svg'
import Building from '../images/build.svg'
import OurOffer from '../images/our_offer.svg'
import { injectIntl, IntlFormatters } from 'gatsby-plugin-intl'
import { SectionWithoutImage } from '../components/sections/section-without-image'
import { SectionLast } from '../components/sections/section-last'

const IndexPage = ({ intl }: IntlFormatters) => {
  return (
    <Layout>
      <SectionLeft
        heading={intl.formatMessage({ id: 'forCompany.sectionOne.heading' })}
        text={
          <>
            <Text>
              {intl.formatMessage({ id: 'forCompany.sectionOne.text1' })}
              <TextBold>{intl.formatMessage({ id: 'forCompany.sectionOne.text2' })}</TextBold>
              {intl.formatMessage({ id: 'forCompany.sectionOne.text3' })}
              <TextBold>{intl.formatMessage({ id: 'forCompany.sectionOne.text4' })}</TextBold>.
            </Text>

            <Text>{intl.formatMessage({ id: 'forCompany.sectionOne.text5' })}</Text>
          </>
        }
        SvgImage={RightPlaceForAds}
      />
      <SectionRight
        heading={intl.formatMessage({ id: 'forCompany.sectionTwo.heading' })}
        text={
          <Text>
            {intl.formatMessage({ id: 'forCompany.sectionTwo.text1' })}
            <TextBold>{intl.formatMessage({ id: 'forCompany.sectionTwo.text2' })}</TextBold>
          </Text>
        }
        SvgImage={RealInterest}
      />
      <SectionLeft
        heading={intl.formatMessage({ id: 'forCompany.sectionThree.heading' })}
        SvgImage={LocalMarketing}
        text={
          <>
            <Text>{intl.formatMessage({ id: 'forCompany.sectionThree.text1' })}</Text>
            <Text>
              <TextBold>{intl.formatMessage({ id: 'forCompany.sectionThree.text2' })}</TextBold>
              {intl.formatMessage({ id: 'forCompany.sectionThree.text3' })}
            </Text>
          </>
        }
      ></SectionLeft>
      <SectionRight
        heading={intl.formatMessage({ id: 'forCompany.sectionFour.heading' })}
        list={
          <span>
            <li>
              <Text>{intl.formatMessage({ id: 'forCompany.sectionFour.text1' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forCompany.sectionFour.text2' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forCompany.sectionFour.text3' })}</Text>
            </li>
          </span>
        }
        SvgImage={Building}
      />
      <SectionWithoutImage
        heading1={intl.formatMessage({ id: 'forCompany.sectionFive.heading1' })}
        text1={<Text>{intl.formatMessage({ id: 'forCompany.sectionFive.text1' })}</Text>}
        heading2={intl.formatMessage({ id: 'forCompany.sectionFive.heading2' })}
        text2={<Text>{intl.formatMessage({ id: 'forCompany.sectionFive.text2' })}</Text>}
      />
      <SectionLast
        heading={intl.formatMessage({ id: 'forCompany.sectionSix.heading' })}
        list={
          <span>
            <li>
              <Text>{intl.formatMessage({ id: 'forCompany.sectionSix.text1' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forCompany.sectionSix.text2' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forCompany.sectionSix.text3' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forCompany.sectionSix.text4' })}</Text>
            </li>
            <li>
              <Text>{intl.formatMessage({ id: 'forCompany.sectionSix.text5' })}</Text>
            </li>
          </span>
        }
        SvgImage={OurOffer}
        button={{
          text: intl.formatMessage({ id: 'forCompany.sectionSix.textButton' }),
          link: intl.formatMessage({ id: 'link.gcms' }),
        }}
      />
      <Footer />
    </Layout>
  )
}

export default injectIntl(IndexPage)
